import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import CookiePreferencesModal from "./CookiePreferencesModal";
import { Button, Link, ChakraProvider } from "@chakra-ui/react";
// import { enableAnalytics } from "./analytics";

const CookieBanner = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBannerVisible, setBannerVisible] = useState(true); // Manage visibility of CookieConsent banner

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // State to track if preferences have been updated
  const [preferencesUpdated, setPreferencesUpdated] = useState(false);

  useEffect(() => {
    // Check if cookie preferences are saved in localStorage
    const storedPreferences = JSON.parse(
      localStorage.getItem("cookiePreferences")
    );

    // If analytics cookies are enabled in the preferences, initialize Google Analytics
    if (storedPreferences && storedPreferences.analytics) {
      // enableAnalytics(); // Call enableAnalytics to initialize Google Analytics
    }
  }, [preferencesUpdated]); // Dependency array includes preferencesUpdated state

  const handlePreferencesChange = () => {
    // Update preferencesUpdated state when preferences are changed
    setPreferencesUpdated((prevState) => !prevState);
    document.cookie = `userConsent=true; path=/`; // Set cookie
    setBannerVisible(false);
  };

  return (
    <>
      <ChakraProvider>
        {isBannerVisible && (
          <CookieConsent
            hideOnAccept
            cookieName="userConsent"
            location="bottom"
            buttonText={"Accept All"}
            buttonStyle={{
              backgroundColor: "#e53e3e",
              color: "#fff",
            }}
            // declineButtonText="Decline"
            // enableDeclineButton
            style={{ background: "#2b2b2b" }}
            // buttonStyle={{
            //   backgroundColor: "#4caf50",
            //   color: "#ffffff",
            //   fontSize: "13px",
            // }}
            declineButtonStyle={{
              backgroundColor: "#f44336",
              color: "#ffffff",
              // fontSize: "13px",
            }}
            // expires={150}
            onAccept={() => {
              // User accepts all cookies
              const defaultPreferences = {
                essential: true,
                analytics: true,
                marketing: true,
              };
              localStorage.setItem(
                "cookiePreferences",
                JSON.stringify(defaultPreferences)
              );
              // enableAnalytics(); // Initialize Google Analytics when the user accepts all cookies
            }}
          >
            This website uses cookies to enhance the user experience. For more
            details visit{" "}
            <Link
              href="https://www.vsnew.com/terms"
              target="_blank"
              style={{ textDecoration: "underline" }}
            >
              vsnew.com/terms
            </Link>
            <Button
              variant="link"
              color="#319795"
              ml={2}
              onClick={openModal}
              style={{ fontSize: "16px" }}
            >
              Manage Preferences
            </Button>
          </CookieConsent>
        )}

        <CookiePreferencesModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onPreferencesChange={handlePreferencesChange}
        />
      </ChakraProvider>
    </>
  );
};

export default CookieBanner;
