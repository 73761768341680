import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  VStack,
  HStack,
  Switch,
  useToast,
} from "@chakra-ui/react";
import { enableAnalytics } from "./analytics";

const CookiePreferencesModal = ({ isOpen, onClose, onPreferencesChange }) => {
  const toast = useToast();

  const [preferences, setPreferences] = useState({
    essential: true, // Always enabled
    analytics: false,
    marketing: false,
  });

  useEffect(() => {
    const storedPreferences = JSON.parse(
      localStorage.getItem("cookiePreferences")
    );
    if (storedPreferences) {
      setPreferences(storedPreferences);
    }
  }, []);

  const handleToggle = (category) => {
    const updatedPreferences = {
      ...preferences,
      [category]: !preferences[category],
    };
    setPreferences(updatedPreferences);
    localStorage.setItem(
      "cookiePreferences",
      JSON.stringify(updatedPreferences)
    );
  };

  const savePreferences = () => {
    localStorage.setItem("cookiePreferences", JSON.stringify(preferences));
    toast({
      title: "Preferences Saved",
      description: "Your cookie preferences have been saved successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });

    // If analytics cookies are enabled, initialize Google Analytics
    if (preferences.analytics) {
      enableAnalytics();
    }

    // Notify parent component about preferences change
    onPreferencesChange();

    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{"Manage Cookie Preferences"}</ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <HStack justify="space-between">
              <Text>Essential Cookies (Always enabled)</Text>
              <Switch
                isChecked={preferences.essential}
                isDisabled
                colorScheme="green"
              />
            </HStack>

            <HStack justify="space-between">
              <Text>Analytics Cookies (Google Analytics)</Text>
              <Switch
                isChecked={preferences.analytics}
                onChange={() => handleToggle("analytics")}
                colorScheme="teal"
              />
            </HStack>

            {/* <HStack justify="space-between">
              <Text>Marketing Cookies</Text>
              <Switch
                isChecked={preferences.marketing}
                onChange={() => handleToggle("marketing")}
                colorScheme="pink"
              />
            </HStack> */}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button
            backgroundColor="#f44336"
            color={"#fff"}
            onClick={savePreferences}
          >
            Save Preferences
          </Button>
          <Button onClick={onClose} ml={3}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CookiePreferencesModal;
